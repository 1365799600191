import {Injectable} from '@angular/core';
import {View, WorkHoursModel} from "@syncfusion/ej2-schedule";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private workHours: WorkHoursModel = {highlight: true, start: '06:00', end: '20:00'};
  private workDays: number[] = [1,2,3,4,5];
  private selectedDate: Date = new Date();
  private currentView: View = 'Week';

  constructor() {
  }


  public calculateEvents(): Date[] {
    let dateArray: Date[] = [];

    // Calculate events
    dateArray.push(new Date());
    dateArray.push(new Date());
    dateArray.push(new Date());

    return dateArray;
  }

  public getWorkHours(): WorkHoursModel {
    return this.workHours;
  }

  public getWorkDays(): number[] {
    return this.workDays;
  }

  public getSelectedDate(): Date {
    return this.selectedDate;
  }

  public getCurrentView(): View {
    return this.currentView;
  }

  public getStartHour(): string {
    return this.workHours.start!;
  }

  public getEndHour(): string {
    return this.workHours.end!;
  }
}
